import {IndexedDbFactory} from "../database/IndexedDbFactory";
import {ErrorResponse} from "../../hooks/fetch/types";
import {EpgPlaylist} from "../../lib/epg/EpgPlaylist";
import {panelApiUserInfoService} from "./PanelApiUserInfoService";
import {panelApiServerInfoService} from "./PanelApiServerInfoService";
import {panelApiCategoryService} from "./PanelApiCategoryService";
import {panelApiChannelService} from "./PanelApiChannelService";


class PanelApiPlaylistService {

    private KEY: string = "paneldata"
    private static _instance: PanelApiPlaylistService;

    private constructor() {
        //Private constructor to prevent direct instantiation
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public async save(playlist: EpgPlaylist): Promise<void> {
        console.log(`PlaylistService.save()`);
        try {
            panelApiUserInfoService.save(playlist.user_info)
            panelApiServerInfoService.save(playlist.server_info)
            panelApiCategoryService.save(playlist.categories)
            panelApiChannelService.save(playlist.available_channels)
            // await IndexedDbFactory.getInstance().set("keyvalue", playlist, this.KEY);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }

    public async remove(): Promise<void> {
        console.log(`PlaylistService.remove()`);
        try {
            await IndexedDbFactory.getInstance().delete("keyvalue", this.KEY);
            console.log(`Spilleliste fjernet`);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }
}

export const panelApiPlaylistService = PanelApiPlaylistService.Instance;
