import {IndexedDbFactory} from "../database/IndexedDbFactory";
import {ErrorResponse} from "../../hooks/fetch/types";
import {ServerInfo} from "../../lib/epg/ServerInfo";


class PanelApiServerInfoService {

    private KEY: string = "server_info"
    private static _instance: PanelApiServerInfoService;

    private constructor() {
        //Private constructor to prevent direct instantiation
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public async get(): Promise<ServerInfo> {
        console.log(`PanelApiServerInfoService.get()`);
        try {
            const data = await IndexedDbFactory.getInstance().get("keyvalue", this.KEY);
            return data
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }

    }

    public async save(serverInfo: ServerInfo): Promise<void> {
        console.log(`PanelApiServerInfoService.save()`);
        try {
            await IndexedDbFactory.getInstance().set("keyvalue", serverInfo, this.KEY);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }

    public async remove(): Promise<void> {
        console.log(`PanelApiServerInfoService.remove()`);
        try {
            await IndexedDbFactory.getInstance().delete("keyvalue", this.KEY);
            console.log(`Spilleliste fjernet`);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }
}

export const panelApiServerInfoService = PanelApiServerInfoService.Instance;
