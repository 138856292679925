import React from 'react';
import "../../App.css";
import {AccountContext} from "../../context/account/AccountContext";
import DownloadPlaylistWithAccountData from "./DownloadPlaylistWithAccountData";
import DownloadPlaylistFromInput from "./DownloadPlaylistFromInput";
import DownloadPlaylistDetaildInfo from "./DownloadPlaylistDetaildInfo";

function DownloadPlaylist() {
    const { data} = React.useContext(AccountContext);

    const showContent = () => {
        if (data) {
            return <>
             <DownloadPlaylistWithAccountData/>
            <DownloadPlaylistDetaildInfo/>
        </>
        } else {
            return <DownloadPlaylistFromInput/>
        }
    }

    return (
        <>
            {showContent()}
        </>
    );
}

export default DownloadPlaylist;
