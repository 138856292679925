import {
    AccountActionTypes,
    REMOVE_SERVER_INFO,
    REMOVE_USER_INFO,
    SET_SERVER_INFO,
    SET_USER_INFO,
    UserAccountState
} from "./types";

export const initialAccountState = {
    userInfo: undefined,
    serverInfo: undefined
};
export const accountReducer = () => (state: UserAccountState, action: AccountActionTypes): UserAccountState => {
    switch (action.type) {
        case SET_USER_INFO:
            return {...state, userInfo: action.payload};
        case SET_SERVER_INFO:
            return {...state, serverInfo: action.payload};
        case REMOVE_SERVER_INFO:
            return {...state, serverInfo: undefined};
        case REMOVE_USER_INFO:
            return {...state, userInfo: undefined};
        default:
            return state;
    }
};