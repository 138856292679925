import React, {useReducer, useState} from 'react';
import "../../App.css";
import {
    contentTypeResponseExtractor,
    jsonResponseExtractor,
    m3uResponseExtractor
} from "../../hooks/fetch/responseExtractors";
import {callBackend} from "../../hooks/fetch/api";
import {getFetchError, getFetchLoading, getFetchSuccess} from "../../hooks/fetch/actions";
import {ErrorResponse} from "../../hooks/fetch/types";
import {fetchReducer, initialFetchState} from "../../hooks/fetch/fetchReducer";
import Button from "../common/Button";
import {AccountContext} from "../../context/account/AccountContext";
import Loading from "../common/Loading";
import {EpgPlaylist} from "../../lib/epg/EpgPlaylist";
import {panelApiPlaylistService} from "../../services/playlist/PanelApiPlaylistService";

function DownloadPlaylistDetaildInfo() {
    const {data} = React.useContext(AccountContext);
    const [isSending, setIsSending] = useState(false)
    const [state, dispatch] = useReducer(fetchReducer<EpgPlaylist>(), initialFetchState);

    const downloadFromInternet = async () => {
        if (data) {
            console.log(`DownloadPlaylistDetaildInfo() - downloadFromInternet`);
            dispatch(getFetchLoading<EpgPlaylist>());
            // return callBackend<EpgPlaylist>("GET", "/assets/iptv_panel_api_small.json", {}, {}, jsonResponseExtractor)
            return callBackend<EpgPlaylist>("GET",
                `${data.url}:${data.port}/panel_api.php?username=${data.username}&password=${data.password}`,
                { accept: "application/json"},
                {},
                jsonResponseExtractor)
        }
    }


    const storePlaylistInDatabase = (playlist?: EpgPlaylist) => {
        console.log("DownloadPlaylistDetaildInfo.storePlaylistInDatabase")

        if (playlist) {
            console.log(`DownloadPlaylistDetaildInfo - Store playlist in database: ${JSON.stringify(playlist)}`)
            panelApiPlaylistService.save(playlist)
                .then(() => {
                    console.log("SaveAll OK!")
                    dispatch(getFetchSuccess<EpgPlaylist>(playlist));
                })
                .catch((error: ErrorResponse) => {
                    console.log(`SaveAll ERROR ${JSON.stringify(error)}`)
                })
        }
    }

    const downloadPlaylist = () => {
        // don't send again while we are sending
        if (isSending) return
        // update state
        setIsSending(true)
        // send the actual request
        downloadFromInternet()
            .then(t => {
                console.log(`DownloadPlaylistDetaildInfo downloadPlaylist() - fetchData - getFetchSuccess: response:${JSON.stringify(t)}`);
                storePlaylistInDatabase(t)
            })
            .catch(error => {
                console.log("DownloadPlaylistDetaildInfo downloadPlaylist() - fetchData - error" + JSON.stringify(error));
                dispatch(getFetchError<EpgPlaylist>(error as ErrorResponse));
            }).finally(() => setIsSending(false)) // once the request is sent, update state again;
    }

    const disableDownloadButton = () => {
        if ((!data) && state.isLoading) {
            return true
        }
        return false
    }

    const showContent = () => {
        if (state.isLoading) {
            return <Loading coverParentOnly={true}/>
        }
        if (state.error) {
            console.log(`Lasting feilet: error: ${JSON.stringify(state.error)}`)
        }

        return <>
            <Button disabled={disableDownloadButton()} text="Last ned spilleliste Panel API" onClick={() => downloadPlaylist()}/>
        </>
    }

    return (
        <>
            {showContent()}
        </>
    );
}

export default DownloadPlaylistDetaildInfo;
