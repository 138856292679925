import {AccountActionTypes, REMOVE_SERVER_INFO, REMOVE_USER_INFO, SET_SERVER_INFO, SET_USER_INFO} from "./types";
import {ServerInfo} from "../../lib/epg/ServerInfo";
import {UserInfo} from "../../lib/epg/UserInfo";


export function removeUserInfo(): AccountActionTypes {
    return {
        type: REMOVE_USER_INFO
    }
}

export function removeServerInfo(): AccountActionTypes {
    return {
        type: REMOVE_SERVER_INFO
    }
}

export function setServerInfo(data: ServerInfo): AccountActionTypes {
    return {
        type: SET_SERVER_INFO,
        payload: data
    };
}

export function setUserInfo(data: UserInfo): AccountActionTypes {
    return {
        type: SET_USER_INFO,
        payload: data
    };
}