import {IndexedDbFactory} from "../database/IndexedDbFactory";
import {ErrorResponse} from "../../hooks/fetch/types";
import {EpgPlaylist} from "../../lib/epg/EpgPlaylist";
import {UserInfo} from "../../lib/epg/UserInfo";
import {Categories} from "../../lib/epg/Categories";


class PanelApiCategoryService {

    private KEY: string = "categories"
    private static _instance: PanelApiCategoryService;

    private constructor() {
        //Private constructor to prevent direct instantiation
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public async get(): Promise<Categories> {
        console.log(`PanelApiCategoryService.get()`);
        try {
            const data = await IndexedDbFactory.getInstance().get("keyvalue", this.KEY);
            return data
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }

    }

    public async save(categories: Categories): Promise<void> {
        console.log(`PanelApiCategoryService.save()`);
        try {
            await IndexedDbFactory.getInstance().set("keyvalue", categories, this.KEY);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }

    public async remove(): Promise<void> {
        console.log(`PanelApiCategoryService.remove()`);
        try {
            await IndexedDbFactory.getInstance().delete("keyvalue", this.KEY);
            console.log(`Spilleliste fjernet`);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }
}

export const panelApiCategoryService = PanelApiCategoryService.Instance;
