import {Channel} from "../../lib/epg/Channel";

export const SET_CHANNEL = "channel/SET_CHANNEL";
export const STOP_CHANNEL = "channel/STOP_CHANNEL";

export type ChannelActionTypes =
    | {type: typeof SET_CHANNEL; payload: Channel }
    | {type: typeof STOP_CHANNEL };

export type ChannelState = {
    data?: Channel;
}

export interface ChannelContextType {
    data?: Channel;
    selectChannel: (channel: Channel) => void;
    removeSelectedChannel: () => void;
}

export const initialChannelContextType: ChannelContextType = {
    data: undefined,
    selectChannel: (channel: Channel) => {},
    removeSelectedChannel: () => {}
} as ChannelContextType;