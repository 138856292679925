import {IndexedDbFactory} from "../database/IndexedDbFactory";
import {ErrorResponse} from "../../hooks/fetch/types";
import {EpgPlaylist} from "../../lib/epg/EpgPlaylist";
import {UserInfo} from "../../lib/epg/UserInfo";


class PanelApiUserInfoService {

    private KEY: string = "user_info"
    private static _instance: PanelApiUserInfoService;

    private constructor() {
        //Private constructor to prevent direct instantiation
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public async get(): Promise<UserInfo> {
        console.log(`PanelApiUserInfoService.get()`);
        try {
            const data = await IndexedDbFactory.getInstance().get("keyvalue", this.KEY);
            return data
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }

    }

    public async save(userInfo: UserInfo): Promise<void> {
        console.log(`PanelApiUserInfoService.save()`);
        try {
            await IndexedDbFactory.getInstance().set("keyvalue", userInfo, this.KEY);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }

    public async remove(): Promise<void> {
        console.log(`PlaylistService.remove()`);
        try {
            await IndexedDbFactory.getInstance().delete("keyvalue", this.KEY);
            console.log(`Spilleliste fjernet`);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }
}

export const panelApiUserInfoService = PanelApiUserInfoService.Instance;
