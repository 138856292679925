import {M3uParser, M3uPlaylist} from "../../lib/m3u";
import {ErrorResponse} from "./types";

export const jsonResponseExtractor = (resp: Response): Promise<any> => {
    return resp.json();
}

export const contentTypeResponseExtractor = (resp: Response): Promise<any> => {

    resp.headers.forEach((value, key) => console.log(`jsonExtractor - resp.header[${key}] = ${value}`))

    if (resp.headers.get("Content-Type")?.startsWith("media/mp2t")) {
        // return resp.text().then(content => content)
        return resp.text() //TODO: Lag støtte for mp2t streams
    }
    if (resp.headers.get("Content-Type")?.startsWith("application/octet-stream")) {
        return resp.text()
    }
    if (resp.headers.get("Content-Type")?.startsWith("text/html")) {
        return resp.text()
    }

    // console.log(`contentTypeResponseExtractor json: ${JSON.stringify(resp.text())}`)
    console.log(`contentTypeResponseExtractor json:`)
    return resp.json();
}

/**
 * Handle Converting of M3U playlist response. response have Content-Type application/octet-stream.
 * But we don't care. We try to convert to playlist no matter what content type it is
 * @param resp
 */
export const m3uResponseExtractor = (resp: Response): Promise<M3uPlaylist> => {

    // resp.headers.forEach((value, key) => console.log(`jsonExtractor - resp.header[${key}] = ${value}`))
    return resp.text()
        .then(text =>
            {
                console.log("parsing text from m3u response into M3uPlaylist object...")
                return M3uParser.parse(text);
            }
        ).catch((error) => {
            console.log("ERROR during parsing text from m3u response into M3uPlaylist object")
            const errorResponse: ErrorResponse = {
                status: 500,
                statusText: error
            }
            return Promise.reject( errorResponse);
        })

}

export const blobExtractor = (resp: Response) => resp.blob()
export const bufferExtractor = (resp: Response) => resp.arrayBuffer()