import React, {PropsWithChildren} from 'react';
import Streaming from "../../components/Streaming";
import styles from "./preview.module.css";
import ChannelsList from "../../components/ChannelsList";
import ChannelsListPanelApi from "../../components/ChannelsList/panelApi";
import {StreamingPanelApi} from "../../components/Streaming/panelapi";

interface Props extends PropsWithChildren<any> {

}

function Preview(props: Props) {

    return (
        <div className={styles.boxcontainer}>
            <div className={styles.box}>
                {/*<ChannelsListPanelApi/>*/}
                <ChannelsList/>
                {/*<ViewCategories/>*/}
            </div>
            <div className={`${styles.box}`}>
                {/*<StreamingPanelApi/>*/}
                <Streaming/>
            </div>
        </div>
    );
}

export default Preview;
