import {IndexedDbFactory} from "../database/IndexedDbFactory";
import {ErrorResponse} from "../../hooks/fetch/types";
import {Channel} from "../../lib/epg/Channel";
import {Channels} from "../../lib/epg/Channels";


class PanelApiChannelService {

    private KEY: string = "available_channels"
    private static _instance: PanelApiChannelService;

    private constructor() {
        //Private constructor to prevent direct instantiation
    }

    public static get Instance()
    {
        return this._instance || (this._instance = new this());
    }

    public async get(): Promise<Channel[]> {
        console.log(`PanelApiChannelService.get()`);
        try {
            const data = await IndexedDbFactory.getInstance().get("keyvalue", this.KEY);
            return Object.values(data) //Dette gjør om indexed signature/key-value struktur til array av value
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }

    }

    public async save(channels: Channels): Promise<void> {
        console.log(`PanelApiChannelService.save()`);
        try {
            await IndexedDbFactory.getInstance().set("keyvalue", channels, this.KEY);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }

    public async remove(): Promise<void> {
        console.log(`PanelApiChannelService.remove()`);
        try {
            await IndexedDbFactory.getInstance().delete("keyvalue", this.KEY);
            console.log(`Spilleliste fjernet`);
            return
        } catch (error) {
            console.log(`Error from getting value: ${error}`);
            throw {
                status: 500,
                statusText: JSON.stringify(error)
            } as ErrorResponse;
        }
    }
}

export const panelApiChannelService = PanelApiChannelService.Instance;
