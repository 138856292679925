import React, {useEffect, useReducer} from 'react';
import {AccountContextType, initialAccountContextType} from "./types";
import {removeUserInfo, setServerInfo, setUserInfo} from "./actions";
import {ErrorResponse} from "../../hooks/fetch/types";
import {accountReducer, initialAccountState} from "./AccountReducer";
import {panelApiUserInfoService} from "../../services/playlist/PanelApiUserInfoService";
import {panelApiServerInfoService} from "../../services/playlist/PanelApiServerInfoService";

export const UserAccountContext = React.createContext<AccountContextType>(initialAccountContextType);

const UserAccountProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [{userInfo, serverInfo}, dispatch] = useReducer(accountReducer(), initialAccountState);

    useEffect(() => {
        console.log("useEffect() Get Account")
        getDataFromDatabase()
    }, []);

    const getDataFromDatabase = () => {
        console.log(`UserAccountProvider: getDataFromDatabase`)
        panelApiUserInfoService.get()
            .then((userInfo) => {
                console.log("AccountProvider: getUserInfo OK!")
                dispatch(setUserInfo(userInfo))
            })
            .catch((error: ErrorResponse) => console.log(`UserAccountProvider: getUserinfo ERROR ${JSON.stringify(error)}`))

        panelApiServerInfoService.get()
            .then((serverInfo) => {
                console.log("AccountProvider: getServerInfo OK!")
                dispatch(setServerInfo(serverInfo))
            })
            .catch((error: ErrorResponse) => console.log(`UserAccountProvider: getServerInfo ERROR ${JSON.stringify(error)}`))
    };

    const deleteUserInfo = () => {
        panelApiUserInfoService.remove()
            .then((account) => {
                console.log("UserInfo Removed")
                dispatch(removeUserInfo());
            })
            .catch((error: ErrorResponse) => console.log(`Removing userInfo ERROR ${JSON.stringify(error)}`))
    }

    const deleteServerInfo = () => {
        panelApiServerInfoService.remove()
            .then((account) => {
                console.log("ServerInfo Removed")
                dispatch(removeUserInfo());
            })
            .catch((error: ErrorResponse) => console.log(`Removing serverInfo ERROR ${JSON.stringify(error)}`))
    }


    return (
        <UserAccountContext.Provider value={{userInfo:userInfo, serverInfo: serverInfo, removeUserInfo: deleteUserInfo, removeServerInfo: deleteServerInfo}}>
            {children}
        </UserAccountContext.Provider>
    );
};

export default UserAccountProvider;