import {ChannelActionTypes, SET_CHANNEL, STOP_CHANNEL} from "./types";
import {Channel} from "../../lib/epg/Channel";

export function removeSelectedChannel(): ChannelActionTypes {
    return {
        type: STOP_CHANNEL
    }
}
export function selectChannel(data: Channel): ChannelActionTypes {
    return {
        type: SET_CHANNEL,
        payload: data
    };
}