import React, {useReducer} from 'react';
import {ChannelContextType, initialChannelContextType} from "./types";
import {initialChannelState, selectedChannelReducer} from "./SelectedChannelReducer";
import {removeSelectedChannel, selectChannel} from "./actions";
import {Channel} from "../../lib/epg/Channel";

export const SelectedChannelContext = React.createContext<ChannelContextType>(initialChannelContextType);

const SelectedChannelProviderPanelApi: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [channel, dispatch] = useReducer(selectedChannelReducer(), initialChannelState);
    const select = (c: Channel) => {
        console.log(`Selected channel: ${JSON.stringify(c)}`)
        dispatch(selectChannel(c));
    };

    const remove = () => {
        console.log(`Removed selected channel`)
        dispatch(removeSelectedChannel());
    };

    return (
        <SelectedChannelContext.Provider value={{data:channel.data, selectChannel: select, removeSelectedChannel: remove}}>
            {children}
        </SelectedChannelContext.Provider>
    );
};

export default SelectedChannelProviderPanelApi;