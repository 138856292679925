import {UserInfo} from "../../lib/epg/UserInfo";
import {ServerInfo} from "../../lib/epg/ServerInfo";

export const SET_USER_INFO = "useraccount/SET_USER_INFO";
export const SET_SERVER_INFO = "useraccount/SET_SERVER_INFO";
export const REMOVE_SERVER_INFO = "useraccount/REMOVE_USER_INFO";
export const REMOVE_USER_INFO = "useraccount/REMOVE_SERVER_INFO";

export type AccountActionTypes =
    | {type: typeof SET_USER_INFO; payload: UserInfo }
    | {type: typeof SET_SERVER_INFO; payload: ServerInfo }
    | {type: typeof REMOVE_SERVER_INFO }
    | {type: typeof REMOVE_USER_INFO };


export type UserAccountState = {
    userInfo?: UserInfo,
    serverInfo?: ServerInfo
}

export interface AccountContextType {
    userInfo?: UserInfo;
    serverInfo?: ServerInfo
    removeUserInfo: () => void;
    removeServerInfo: () => void;
}

export const initialAccountContextType: AccountContextType = {
    userInfo: undefined,
    serverInfo: undefined,
    removeUserInfo: () => {},
    removeServerInfo: () => {}
} as AccountContextType;
